import React from 'react'

import { useLocation } from "@reach/router" // this helps tracking the location
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

import { Link } from 'gatsby'
import base from './base.css'

import Container from './container'
import Navigation from './navigation'
import Footer from './footer'
import MouseContextProvider, { MouseContext } from '../context/MouseContext'

class Template extends React.Component {
  render() {
    const { location, children } = this.props
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <Container background={this.props.background} fullHeight={this.props.fullHeight}>
        <MouseContextProvider>
          <Navigation location={this.props.location} wide={this.props.wideNav} white={this.props.white} background={this.props.background}/>
          {children}

          {!this.props.noFooter && 
            <Footer/> 
          }

          <CookieConsent
            disableStyles={true}
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            enableDeclineButton
            cookieName="gatsby-gdpr-analytics"
            debug={false}
            onAccept={() => {
              initializeAndTrack(location);
            }}
            >
            <img src="images/cookie-jar.png" alt="South North cookie jar"/>
            <div>
              <h3>We like cookies, but also value your privacy</h3>
              <span>
                We use cookies to enhance your browsing experience and to analyze our traffic.
                <br></br>
                By clicking Accept, you consent to our use of cookies.
              </span>
            </div>
          </CookieConsent>
        </MouseContextProvider>
      </Container>
    )
  }
}

export default Template
