import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.menu = React.createRef();
    this.menuButton = React.createRef();
  }

  render() {
    return (
      <nav role="navigation" style={{backgroundColor: this.props.background}}>
        <div className={styles.navigationcontent + " content " + (this.props.white === true ? styles.white: "")}>
          <div className={styles.logo}>
            <Link to="/">
                <img className={styles.compact + " " + (this.props.white ? styles.logoWhite : "")} alt="South North Logo" src="/images/logo-horizontal.svg"></img>
            </Link>
          </div>
          <button aria-label="Menu button" className={styles.menuButton + " " + (this.props.white === true ? styles.white: "")} ref={this.menuButton} onClick={()=>{this.menu.current.classList.toggle(styles.open); this.menuButton.current.classList.toggle(styles.open); document.getElementById("root-element").classList.toggle("block-scroll");}}>
            <span></span>
            <span></span>
          </button>
          <ul className={styles.navigation} ref={this.menu}>
            {/* <li className={styles.navigationItem + (["/training","/simulators","/websites","/mobile","/exhibitions","/press-events","/metaverse"].indexOf(this.props.location.pathname) > -1 ? " " + styles.currentPage : "")}>
              <img className={styles.dropDownArrow} src="/images/arrow.svg"></img>
              Experiences
              <ul className={styles.subNavigation}>
                <li className={styles.navigationItem  + (this.props.location.pathname.includes("/training") ? " " + styles.currentPage : "")}>
                  <Link to={"/training"}>Training</Link>
                </li>
                <li className={styles.navigationItem + (this.props.location.pathname.includes("/simulators") ? " " + styles.currentPage : "")}>
                  <Link to={"/simulators"}>Simulators</Link>
                </li>
                <li className={styles.navigationItem + (this.props.location.pathname.includes("/websites") ? " " + styles.currentPage : "")}>
                  <Link to={"/websites"}>Websites</Link>
                </li>
                <li className={styles.navigationItem + (this.props.location.pathname.includes("/mobile") ? " " + styles.currentPage : "")}>
                  <Link to={"/mobile"}>Mobile</Link>
                </li>
                <li className={styles.navigationItem + (this.props.location.pathname.includes("/exhibitions") ? " " + styles.currentPage : "")}>
                  <Link to={"/exhibitions"}>Exhibitions</Link>
                </li>
                <li className={styles.navigationItem + (this.props.location.pathname.includes("/press-events") ? " " + styles.currentPage : "")}>
                  <Link to={"/press-events"}>Press Events</Link>
                </li>
                <li className={styles.navigationItem + (this.props.location.pathname.includes("/metaverse") ? " " + styles.currentPage : "")}>
                  <Link to={"/metaverse"}>Metaverse</Link>
                </li>
              </ul>
            </li> */}
            <li className={styles.navigationItem + (this.props.location.pathname.includes("/cases") ? " " + styles.currentPage : "")}>
              <Link to="/cases">Work</Link>
            </li>
            {/* <li className={styles.navigationItem + (this.props.location.pathname.includes("/about") ? " " + styles.currentPage : "")}>
              <Link to="/about">About</Link>
            </li> */}
            <li className={styles.navigationItem + (this.props.location.pathname.includes("/contact") ? " " + styles.currentPage : "")}>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}

export default Navigation