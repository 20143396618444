import React from 'react'
import { Link } from 'gatsby'
import styles from './footer.module.css'

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className={styles.blackFooter} style={{background: this.props.background}}>
          <div className={"content " + styles.blackFooterContent}>
            <div className={styles.social}>
              <a href="https://www.instagram.com/southnorthstudios/">Instagram</a>
              <a href="https://twitter.com/SouNorStu">Twitter</a>
              <a href="https://www.facebook.com/outherevr">Facebook</a>
              <a href="https://www.linkedin.com/company/southnorthstudios">LinkedIn</a>
            </div>
            <div className={styles.wordmark}><Link to="/"><img alt="South North logo symbols" src="/images/symbols.svg"/></Link></div>
            <div className={styles.email}>
              <a href="mailto:hello@southnorth.se">hello@southnorth.se</a>
            </div>
            <div className={styles.address}>
              <p>Vallgatan 21<br/>411 16 Gothenburg<br/>SWEDEN</p>
            </div>
            <div className={styles.copyright}>
              <p>© South North Studios AB 2023. All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
